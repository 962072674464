import React, { useState } from 'react';
import { format } from 'date-fns';
import './ConsultationBooking.css';
import BookingForm from '../BookingForm/BookingForm.js'; // Import the BookingForm component

const ConsultationBooking = ({ selectedDate, onBack }) => {
  const [selectedTime, setSelectedTime] = useState(null);

  const timeSlots = {
    morning: ['9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM'],
    afternoon: ['1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM']
  };

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
  };

  const handleChooseLater = () => {
    setSelectedTime('Choose Later');
  };

  return selectedTime ? (
    <BookingForm 
      selectedDate={selectedDate === 'Choose Later' ? 'Choose Later' : format(selectedDate, 'MMMM d, yyyy')} 
      selectedTime={selectedTime} 
      onBack={() => setSelectedTime(null)} 
    />
  ) : (
    <div className="consultation-container">
      <h2>Consultation Phone Call</h2>
      <p>30 minutes</p>
      <h3>Select a time on {selectedDate === 'Choose Later' ? 'Choose Later' : format(selectedDate, 'MMMM d, yyyy')}</h3>
      <div className="time-selection-container">
        <div className="time-column">
          <div className="time-header">
            <span role="img" aria-label="sunrise">🌅</span>
            <h4>Morning</h4>
          </div>
          {timeSlots.morning.map((time, index) => (
            <button
              key={index}
              className={`time-button ${selectedTime === time ? 'selected' : ''}`}
              onClick={() => handleTimeSelection(time)}
            >
              {time}
            </button>
          ))}
        </div>
        <div className="time-column">
          <div className="time-header">
            <span role="img" aria-label="sun">☀️</span>
            <h4>Afternoon</h4>
          </div>
          {timeSlots.afternoon.map((time, index) => (
            <button
              key={index}
              className={`time-button ${selectedTime === time ? 'selected' : ''}`}
              onClick={() => handleTimeSelection(time)}
            >
              {time}
            </button>
          ))}
        </div>
      </div>
      <button 
        className="choose-later-button" 
        onClick={handleChooseLater}
      >
        Choose Later
      </button>
    </div>
  );
};

export default ConsultationBooking;
