import React from 'react';

const FileUploadPage = () => {
  return (
    <div>
      {/* <FileUpload /> */}
    </div>
  );
};

export default FileUploadPage;
