import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-left">
        <a href="#privacy">Dorganic's Privacy Policy</a>
        <a href="#refund">Dorganic's Refund & Return Policy</a>
        <a href="#terms">Dorganic's Terms & Conditions</a>
      </div>

      <div className="footer-center">
        <p>© 2024 Dr Dorganic's | Developed by <a href="https://tsoftware.com">T Software</a></p>
      </div>

      <div className="footer-right">
        <a href="#facebook"><i className="fab fa-facebook-f"></i></a>
        <a href="#twitter"><i className="fab fa-twitter"></i></a>
        <a href="#instagram"><i className="fab fa-instagram"></i></a>
      </div>
    </footer>
  );
}

export default Footer;
