import React, { useState, useEffect } from 'react';
import { format, addDays, isWeekend } from 'date-fns';
import './Consultation.css';
import ConsultationBooking from '../ConsultationBooking/ConsultationBooking'; // Import the ConsultationBooking component

const ConsultationPage = () => {
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  // Generate the next 7 days excluding weekends
  useEffect(() => {
    const next7Days = [];
    let currentDate = new Date();

    while (next7Days.length < 7) {
      currentDate = addDays(currentDate, 1); // Move to the next day
      if (!isWeekend(currentDate)) { // Exclude weekends
        next7Days.push(currentDate);
      }
    }

    setDates(next7Days);
  }, []);

  const handleDateSelection = (date) => {
    setSelectedDate(date);
  };

  const handleChooseLater = () => {
    setSelectedDate('Choose Later');
  };

  return (
    <div className="welcome-container">
      {!selectedDate ? (
        <>
          <h2>Consultation Phone Call</h2>
          <p>30 minutes</p>
          <h3>Select a date</h3>
          <div className="date-picker">
            {dates.map((date, index) => (
              <div 
                key={index} 
                className={`date-item ${isWeekend(date) ? 'weekend' : ''}`} 
                onClick={() => handleDateSelection(date)}
              >
                {format(date, 'EEE')} <br />
                {format(date, 'MMMM d, yyyy')}
              </div>
            ))}
          </div>
          <button 
            className="choose-later-btn"
            onClick={handleChooseLater}
          >
            Choose Later
          </button>
        </>
      ) : (
        <ConsultationBooking selectedDate={selectedDate} />
      )}
    </div>
  );
};

export default ConsultationPage;
