import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import ConsultationPage from './components/Consultation/ConsultationPage';
import FileUploadPage from './pages/FileUploadPage';
import BookingFormPage from './pages/BookingFormPage';
import ConsultationBookingPage from './pages/ConsultationBookingPage';
import Subscription from './components/Subscription/Subscription';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            {/* <Route path="/" element={<Home />} /> Home page route */}
            <Route path="/consultation" element={<ConsultationPage />} /> {/* Consultation page */}
            <Route path="/upload-file" element={<FileUploadPage />} /> {/* File upload page */}
            <Route path="/booking-form" element={<BookingFormPage />} /> {/* Booking form page */}
            <Route path="/consultation-booking" element={<ConsultationBookingPage />} /> {/* Consultation booking page */}
            <Route path="/subscription" element={<Subscription />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
