import React, { useState } from 'react';
import './Header.css';
import newLogo from '../../assets/logo.webp'; // Import the new transparent image

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header-container">
      <div className="header-content">
        <div className="logo-section">
          <img src={newLogo} alt="Dr Priya Skin & Laser Centre Logo" className="logo" />
        </div>

        <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <a href="https://drpriyaskin.in/">Home</a>
          <a href="https://drpriyaskin.in/about/">About</a>
          <a href="https://drpriyaskin.in/contact/">Contact</a>
        </nav>

        <div className="appointment-button">
          <a href="https://drpriyaskin.in/book-appointment/">
            <button>Book an Appointment</button>
          </a>
        </div>

        <div className="menu-icon" onClick={toggleMenu}>
          ☰
        </div>
      </div>
    </header>
  );
}

export default Header;
