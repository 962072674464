import React, { useState, useEffect } from 'react';
import './AboutUsSection.css'; // Import AboutUsSection-specific CSS

const images = [
  require('../assets/homePage-3.webp'),
  require('../assets/homePage-4.webp'),
  require('../assets/homePage-5.webp'),
  require('../assets/homePage-6.webp'),
];

const AboutUsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState('');

  // Function to move to the next image
  const handleNext = () => {
    setSlideDirection('next');
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setSlideDirection('');
    }, 500); // Match the duration of the CSS sliding animation
  };

  // Function to move to the previous image
  const handlePrev = () => {
    setSlideDirection('prev');
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setSlideDirection('');
    }, 500);
  };

  // Automatically move to the next image every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000); // 5000ms = 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [currentIndex]); // Re-run effect when the currentIndex changes

  return (
    <section className="about-us-section">
      <h2>About Us</h2>
      <div className="about-us-content">
        <div className="about-us-text">
          <h3>Meet Dr. Priya Mhatre - A Pioneer in Dermatology & Cosmetology</h3>
          <p>
            Dr. Priya Mhatre Kudai is a Government Certified Dermatologist and Cosmetologist with over 17 years of experience in the field. 
            She established Dr. Priya’s Skin & Laser Centre in 2007, introducing advanced skin and laser treatments to Bhiwandi City. 
            Her commitment to patient care has earned her a reputation for excellence, attracting patients from across India.
          </p>
          <h4>A Legacy of Trust and Care</h4>
          <button className="about-us-button">Know More →</button>
        </div>

        {/* Image Carousel */}
        <div className={`about-us-carousel ${slideDirection === 'next' ? 'slide-next' : slideDirection === 'prev' ? 'slide-prev' : ''}`}>
          <button className="prev-button" onClick={handlePrev}>❮</button>
          <img src={images[currentIndex]} alt="About Us Carousel" className="carousel-image" />
          <button className="next-button" onClick={handleNext}>❯</button>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
