import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './Subscription.css';

const Subscription = () => {
  const location = useLocation();
  const { formData, selectedDate, selectedTime } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Log the selected date and time
  console.log('Selected Date:', selectedDate);
  console.log('Selected Time:', selectedTime);

  // Define plans array with proper structure
  const plans = [
    {
      id: 1,
      name: 'Short-Term Program',
      price: '₹299',
      description: 'Revitalize your system with a focused cleanse designed to reset your body.',
      active: false
    },
    {
      id: 2,
      name: 'Monthly Program',
      price: '₹999',
      description: 'A comprehensive monthly plan for sustainable wellness and lifestyle changes.',
      active: false
    },
    {
      id: 3,
      name: 'Quarterly Program',
      price: '₹2499',
      description: 'Deep dive into holistic health with 3 months of dedicated support and guidance.',
      active: false
    },
    {
      id: 4,
      name: 'Half-Yearly Program',
      price: '₹4999',
      description: 'Intensive 6-month transformation journey with personalized attention.',
      active: false
    },
    {
      id: 5,
      name: 'Annual Program',
      price: '₹8999',
      description: 'Complete year-long wellness journey with premium support and exclusive benefits.',
      active: false
    }
  ];

  const handlePlanSelection = async (planId) => {
    // First update the UI to show selection
    const updatedPlans = plans.map(plan => ({
      ...plan,
      active: plan.id === planId
    }));
    setSelectedPlan(updatedPlans.find(plan => plan.active));

    // Immediately proceed with payment
    if (!formData?.name || !formData?.phone || !formData?.email) {
      alert('Missing user information. Please fill in all required fields.');
      return;
    }

    setLoading(true);
    try {
      const selectedPlan = updatedPlans.find(plan => plan.active);
      
      const paymentData = {
        transactionId: `TX_${Date.now()}`,
        MUID: formData.email.split('@')[0] || 'user',
        name: formData.name,
        amount: parseInt(selectedPlan.price.replace(/[₹,]/g, '')),
        number: formData.phone
      };

      console.log('Payment payload:', paymentData);

      const response = await axios.post(
        'https://54n24jytpd.execute-api.ap-south-1.amazonaws.com/prod/order',
        paymentData
      );

      console.log('Full API response:', response);

      if (response.data.success && response.data.data?.instrumentResponse?.redirectInfo?.url) {
        window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
      } else {
        throw new Error('Payment URL not received from server');
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert('Payment failed: ' + (error.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="subscription-container">
      <h2>You are booking: Consultation Phone Call</h2>
      <p>{`${selectedDate} ${selectedTime}`}</p>

      <div className="plans">
        {plans.map(plan => (
          <div 
            key={plan.id}
            className={`plan ${plan.active ? 'active' : ''} ${loading ? 'disabled' : ''}`}
            onClick={() => !loading && handlePlanSelection(plan.id)}
          >
            <h3>{plan.name}</h3>
            <p>{plan.description}</p>
            <p><strong>{plan.price}</strong></p>
            {loading && plan.active && <div className="loading-indicator">Processing...</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subscription;
