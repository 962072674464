import React from 'react';
import './ServicesSection.css'; // Services section-specific CSS

const services = [
  {
    title: 'Skin Treatments',
    image: require('../assets/homePage-3.webp'), // Updated image path
    description: 'Explore a range of treatments designed to nourish and treat your skin.',
    link: '#',
  },
  {
    title: 'Hair Treatments',
    image: require('../assets/homePage-4.webp'), // Updated image path
    description: 'From hair fall control to scalp treatments, we provide personalized solutions.',
    link: '#',
  },
  {
    title: 'Cosmetic Procedures',
    image: require('../assets/homePage-5.webp'), // Updated image path
    description: 'Advanced procedures for enhancing your natural beauty.',
    link: '#',
  },
  {
    title: 'SPA & Wellness',
    image: require('../assets/homePage-6.webp'), // Updated image path
    description: 'Relax, rejuvenate, and refresh with our range of wellness treatments.',
    link: '#',
  },
];

const ServicesSection = () => {
  return (
    <section className="services-section">
      <h2>Our Services</h2>
      <p className="services-description">
        Comprehensive Skin Care Solutions Under One Roof
      </p>
      <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.image} alt={service.title} className="service-image" />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <a href={service.link} className="service-button">
              Know More →
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
