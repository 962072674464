import React from 'react';
import ConsultationBooking from '../components/ConsultationBooking/ConsultationBooking';

const ConsultationBookingPage = () => {
  return (
    <div>
      <ConsultationBooking />
    </div>
  );
};

export default ConsultationBookingPage;
